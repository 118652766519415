<template>
  <v-card elevation="0" color="transparent">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="12" md="4" lg="4">
        <div class="d-flex justify-center">
          <v-img
            v-if="providerLogo"
            height="238"
            width="248"
            :src="providerLogo"
          />
          <v-icon v-else size="200"> mdi-image-outline </v-icon>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8">
        <div class="d-flex flex-column mx-4">
          <p class="text-body-2 mb-0">Nombre del proveedor:</p>
          <p class="secondary--text">{{ providerData?.nombre }}</p>
        </div>
        <div class="d-flex flex-column mx-4">
          <p class="text-body-2 mb-0">Correo electrónico:</p>
          <p class="secondary--text">{{ providerData?.email }}</p>
        </div>
        <div class="d-flex flex-column mx-4">
          <p class="text-body-2 mb-0">Sitio web:</p>
          <p class="secondary--text">{{ providerData?.sitio_web }}</p>
        </div>
        <div class="d-flex flex-column mx-4">
          <p class="text-body-2 mb-0">Valoraciones:</p>
          <v-rating
            readonly
            half-increments
            color="secondary"
            background-color="secondary"
            length="5"
            :value="ratingProvider"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ProviderAgreementDetailComponent",
  data: () => ({
    providerData: {},
    ratingProvider: 0,
  }),
  computed: {
    ...mapState("convenioMarcoAdmin", ["tempProviderId", "breadcrumbsItems"]),
    ...mapState("proveedores", ["providerLogo"]),
  },
  methods: {
    ...mapActions("proveedores", ["getProviderLogo"]),
    async fetchCalificacion() {
      const idProveedor = this.tempProviderId;
      const { data } = await this.services.Proveedores.getCalificacionProveedor(
        idProveedor
      );
      if (data) {
        this.ratingProvider = Number.parseFloat(data?.calificacion);
      }
    },
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.tempProviderId
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
        this.breadcrumbsItems.push({
          text: this.providerData.nombre,
        });
      }
    },
  },
  created() {
    if (this.tempProviderId) {
      this.getProviderLogo(this.tempProviderId);
      this.fetchProviderData();
      this.fetchCalificacion();
    } else {
      this.$router.push({ name: "convenio-marco" });
    }
  },
  beforeDestroy() {
    this.breadcrumbsItems.pop();
  },
};
</script>
