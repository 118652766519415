<template>
  <section>
    <div>
      <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">
        <ProviderAgreementDetail />
      </v-col>
    </v-row>
    <br />
    <br />
    <v-row>
      <v-col cols="12" sm="12" class="aling-center">
        <v-data-table
          :headers="headers"
          :items="reviewOffers"
          hide-default-footer
          item-key="id"
          no-data-text="No hay datos disponibles"
        >
          <template v-slot:[`item.obs`]="{ item }">
            {{ item.codigo }} - {{ item.nombre_obs }}
          </template>
          <template #[`item.aprobado`]="{ item }">
            <v-chip label :color="getColor(item.aprobado)" :text-color="item.aprobado === true ? 'white' : 'black'">{{ item.aprobado === true ? 'Aprobado' : 'No seleccionado' }}</v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <div class="align-self-center d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="secondary"
                    @click="showObsDetail(item)"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span> Ver </span>
              </v-tooltip>
              <v-tooltip v-if="!readOnlyOffers" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="mx-2"
                    color="secondary"
                    @click="changeStatusProvider(item)"
                  >
                    <v-icon>{{
                      !item.aprobado ? "mdi-crop-square" : "mdi-checkbox-marked"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    item.aprobado
                      ? "Remover del convenio"
                      : "Incluir al convenio"
                  }}
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- botones atrás guardar selección-->

    <v-row>
      <v-col cols="8" sm="3" md="4" lg="4">
        <v-btn class="ma-2" outlined @click="regresa()"> Atrás </v-btn>
      </v-col>
    </v-row>

    <!--------Modal vista obs------------>
    <v-dialog v-model="dialogdetalleobs" width="1000">
      <v-card>
        <div class="d-flex px-3 pt-3">
          <v-btn @click="dialogdetalleobs = false" large icon
            ><v-icon>mdi-window-close</v-icon></v-btn
          >
        </div>
        <div class="px-15 pb-15">
          <ObsPreview :obs="obsPresentationPreview" />
        </div>

        <v-btn
          class="ma-2"
          outlined
          @click="(dialogdetalleobs = false), (selectedPresentation = {})"
        >
          Atrás
        </v-btn>

        <v-btn
          v-if="!readOnlyOffers"
          class="ma-2"
          color="secondary"
          dark
          @click="changeStatusProvider(selectedPresentation)"
        >
          {{
            selectedPresentation.aprobado
              ? "Remover del convenio"
              : "Incluir al convenio"
          }}
        </v-btn>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import ProviderAgreementDetail from "./components/ProviderAgreementDetailComponent.vue";
import ObsPreview from "../../components/ObsPreviewComponent.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "evaluacionProveedorView",
  components: {
    ProviderAgreementDetail,
    ObsPreview,
  },

  data: () => ({
    dialogdetalleobs: false,
    obsPresentationPreview: {},
    selectedPresentation: {},
    headers: [
      { text: "OBS", align: "start", sortable: false, value: "obs" },
      {
        text: "Presentación",
        align: "center",
        sortable: false,
        value: "nombre_presentacion",
      },
      { text: "Cantidad", align: "center", sortable: false, value: "cantidad" },
      { text: "Estado", align: "center", sortable: false, value: "aprobado" },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
  }),
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "reviewOffers",
      "breadcrumbsItems",
      "agreement_detail",
      "tempProviderId",
      "readOnlyOffers",
    ]),
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", ["getReviewOffers"]),
    regresa() {
      this.$router.push("/evaluacion-ofertas");
    },
    async showObsDetail(item) {
      let response =
        await this.services.AgreementMarco.getConvenioOBSPresentationDetail(
          item.id
        );
      if (response.status == 200) {
        this.obsPresentationPreview = response?.data;
        this.selectedPresentation = item;
        this.dialogdetalleobs = true;
      }
    },
    getColor(aprobado) {
      if(aprobado === true) {
        return '#111C4E'
      } else {
        return '#E0E5F1'
      }
    },
    async changeStatusProvider(item) {
      let response =
        await this.services.AgreementMarco.changeStatusPresentation(item.id);
      if (response?.status == 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Estado actualizado correctamente",
        });
        this.getReviewOffers({
          id_agreement: this.agreement_detail.id,
          id_provider: this.tempProviderId,
        });
        this.dialogdetalleobs = false;
      }
    },
  },
};
</script>
